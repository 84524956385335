import { AppBar, Box, Grid, IconButton, Toolbar, Tooltip, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import MenuOutlined from "@mui/icons-material/MenuOutlined";
import { CloseTwoTone, MenuTwoTone } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { SidebarContext } from "../SidebarLayout/contexts/SidebarContext";

const Topbar = () => {
  const theme = useTheme();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const [x,setY] = useState(true);
// console.log(toggleSidebar)
  const z =() =>{
    setY(!x)
    toggleSidebar();
  }


  const Main = styled('AppBar', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('lg')]: {
          marginLeft: `${theme.sidebar.width}`
        },
      }),
    }),
  );

  return (
    
    // <Main open={sidebarToggle}  sx={{backgroundColor:'#e74326',display:'flex',}} >
        <AppBar color="default" position="sticky" elevation={1}>

      <Grid container>
        <Grid item xs={6} md={3}>

          {/* SEARCH BAR */}
          {/* <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            pl={2}
          >
            <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box> */}
            <Box
              component="span"
              sx={{
                ml: 2,
                display:'inline-block'
              }}
            >
              {!sidebarToggle ? (
                <Tooltip arrow title="Open Menu">
                      <IconButton  color="primary" onClick={toggleSidebar}>
                          <MenuTwoTone fontSize="small" />
                      </IconButton>
                </Tooltip>
                ) : (
                  null
                )}
        </Box>
        </Grid>
        <Grid item xs={6} md={9} sx={{justifyContent:"flex-end", display:'flex',pr:2}}>
          <Box display="flex">
            <IconButton>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
            <IconButton>
              <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
            <IconButton>
              <PersonOutlinedIcon />
            </IconButton>

          </Box>
        </Grid>
      </Grid>
      </AppBar>
  );
};

export default Topbar;
