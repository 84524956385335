import { Suspense, lazy} from 'react';
import SidebarLayout from './components/Main Admin/Global/SidebarLayout';

import SuspenseLoader from './components/SuspenseLoader/index';
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const LatestWork = Loader(lazy(() => import('./components/Main Admin/Pages/LatestWork/LatestWork')));
const Employee = Loader(lazy(() => import('./components/Main Admin/Pages/Employee/Employee')));
const Product = Loader(lazy(() => import('./components/Main Admin/Pages/Product/Product')));
const Services = Loader(lazy(() => import('./components/Services/Services')));
const Home = Loader(lazy(() => import('./components/Home/Home')));
const MainLayout = Loader(lazy(() => import('./components/Main Admin/Global/Navbar/MainLayout')));
const GetInTouch = Loader(lazy(() => import('./components/GetInTouch/GetInTouch')));
const PageNotFound = Loader(lazy(() => import('./components/Error Page/PageNotFound')));

const routes =  [
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <LatestWork />
      },
      {
        path: 'employee',
        element: <Employee />
      },
      {
        path: 'latest',
        element: <LatestWork />
      },
      {
        path: 'services',
        element: <Services />
      },
      {
        path: 'product',
        element: <Product />
      },
      {
        path: 'contact-us',
        element: <GetInTouch />
      },
      {
        path: '*',
        element: <PageNotFound />
      },

    ]
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: '*',
        element: <PageNotFound />
      },
    ]
  }
];
export default routes;