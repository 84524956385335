const initialState = {
    company:[],
};

const companyReducer = (state = initialState,action) =>{
    switch (action.type){
        case "FETCH_COMPANY":
            return {...state, company: action.payload};
        case "FETCH_ALL_COMPANY":
            return {...state, company: action.payload};
        case "CREATE_COMPANY":
            return { ...state, company: [...state.company, action.payload] };

        case "UPDATE_COMPANY":
            return {...state, company: state.company.map((company)=> (company._id === action.payload._id?action.payload:company))};
        case "DELETE_COMPANY":
            return {...state, company: state.company.map((company) => company._id !== action.payload) };
        default:
            return state;

    }
}

export default companyReducer;