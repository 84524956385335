import { START_LOADING, END_LOADING} from '../constants/actionTypes';


const initialState = {
    latestWork:[],
    isLoading:false,
};

const latestWorkReducer = (state = initialState,action) =>{
    switch (action.type){
        case START_LOADING:
            return {...state, isLoading:true}
        case END_LOADING:
            return {...state, isLoading:false}
        case "FETCH_LATEST_WORK":
            return {...state, latestWork: action.payload};
        case "FETCH_ALL_LATEST_WORK":
            return {...state, latestWork: action.payload};
        case "CREATE_LATEST_WORK":
            return { ...state, latestWork: [...state.latestWork, action.payload] };

        case "UPDATE_LATEST_WORK":
            return {...state, latestWork: state.latestWork.map((latestWork)=> (latestWork._id === action.payload._id?action.payload:latestWork))};
        case "DELETE_LATEST_WORK":
            return {...state, latestWork: state.latestWork.filter((work) => work._id !== action.payload) };
        default:
            return state;

    }
}

export default latestWorkReducer;