const initialState = {
    user:[],
};

const userReducer = (state = initialState,action) =>{
    switch (action.type){
        case "FETCH_USER":
            return {...state, user: action.payload};
        case "FETCH_ALL_USER":
            return {...state, user: action.payload};
        case "CREATE_USER":
            return { ...state, user: [...state.user, action.payload] };

        case "UPDATE_USER":
            return {...state, user: state.user.map((user)=> (user._id === action.payload._id?action.payload:user))};
        case "DELETE_USER":
            return {...state, user: state.user.filter((user) => user._id !== action.payload) };
        default:
            return state;

    }
}

export default userReducer;