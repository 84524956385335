import { START_LOADING, END_LOADING, FETCH_SERVICE, FETCH_ALL_SERVICE, CREATE, UPDATE, DELETE} from '../constants/actionTypes';

const initialState = {
    service:[],
    isLoading: true,
};

const serviceReducer = (state = initialState,action) =>{
    switch (action.type){
        case START_LOADING:
            return {...state, isLoading:true}
        case END_LOADING:
            return {...state, isLoading:false}
        case FETCH_SERVICE:
            return {...state, services: action.payload};
        case FETCH_ALL_SERVICE:
            return {...state, service: action.payload};
        case CREATE:
            return { ...state, service: [...state.service, action.payload] };

        case UPDATE:
            return {...state, service: state.service.map((service)=> (service._id === action.payload._id?action.payload:service))};
        case DELETE:
            return {...state, service: state.service.map((service) => service._id !== action.payload) };
        default:
            return state;

    }
}

export default serviceReducer;