import { useContext } from 'react';

import { Box,Drawer,alpha, styled, Divider, useTheme, lighten, darken, Tooltip, IconButton } from '@mui/material';
import { CloseTwoTone, MenuTwoTone } from '@mui/icons-material';

import Scrollbar from '../Scrollbar';
import { SidebarContext } from '../contexts/SidebarContext';
import SidebarMenu from './SidebarMenu';


const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: 340;
        min-width: 340;
        color: #ddf5dd};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
    <Drawer variant="persistent" anchor="left" open={sidebarToggle} onClose={closeSidebar}
        sx={{ width: `340px`, flexShrink: 0, display: { xs: 'inline-block', lg: 'inline-block'},
          '& .MuiDrawer-paper': { width: `250px`,  backgroundColor:'#07233f' , boxSizing: 'border-box', overflow: 'auto', }  }}>

      <SidebarWrapper sx={{ position: 'fixed', left: 0,top: 0,  }} >

        <Box component="span" sx={{ mt: 1, mr: 1, display:'flex', alignItems:"center", justifyContent:'space-between'}} >
            <Box mx={2} sx={{ width: '100%' }} >
              EthioLiq Admin
            </Box>
            {sidebarToggle ? (
              <Tooltip arrow title="Close Menu">
                <IconButton color="primary" onClick={toggleSidebar}>
                  <CloseTwoTone fontSize="small" />
                </IconButton>
              </Tooltip>
              ) : (null)
            }
        </Box>
        <Scrollbar>
          <Divider sx={{ mx: theme.spacing(2), background: '#f44ess'}} />
          <SidebarMenu />
        </Scrollbar>
        <Divider sx={{  background: '#f44ess'}}/>
      </SidebarWrapper>
    </Drawer>
    </>
  );
}

export default Sidebar;
