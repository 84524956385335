import { combineReducers } from 'redux';

// import posts from './posts';
import auth from './auth';
import serviceReducer from './service';
import companyReducer from './company';
import latestWorkReducer from './latestWork';
import userReducer from './user';

// export const reducers = service();
export const reducers = combineReducers({  service:serviceReducer ,company:companyReducer , latestWork:latestWorkReducer, user:userReducer, auth});
