import { HelmetProvider } from 'react-helmet-async';
import React,{useEffect} from 'react';
import { useRoutes } from 'react-router-dom';

import { CssBaseline } from "@mui/material";
import routes from './routes';
import "./App.css";
import { SnackbarProvider } from 'notistack';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: 'linear',
      delay: 300,
    });
    AOS.refresh();
  }, []);
  const content =  useRoutes(routes);
  return (
    <HelmetProvider>
      <SnackbarProvider>
          <CssBaseline />
          {content}
      </SnackbarProvider>
    </HelmetProvider>
  );
}
export default App;