export const CREATE = 'CREATE';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_LATEST_WORK = 'CREATE_LATEST_WORK';
export const CREATE_COMPANY = 'CREATE_COMPANY';

export const UPDATE = 'UPDATE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_LATEST_WORK = 'UPDATE_LATEST_WORK';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';

export const DELETE = 'DELETE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_LATEST_WORK = 'DELETE_LATEST_WORK';
export const DELETE_COMPANY = 'DELETE_COMPANY';

export const FETCH_ALL_SERVICE = 'FETCH_ALL_SERVICE';
export const FETCH_ALL_USER = 'FETCH_ALL_USER';
export const FETCH_ALL_LATEST_WORK = 'FETCH_ALL_LATEST_WORK';
export const FETCH_ALL_COMPANY = 'FETCH_ALL_COMPANY';

export const FETCH_SERVICE = 'FETCH_SERVICE';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_LATEST_WORK = 'FETCH_LATEST_WORK';
export const FETCH_COMPANY = 'FETCH_COMPANY';

export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';