import { useContext } from "react";
import { Box, styled} from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import { SidebarContext } from './contexts/SidebarContext';
import Topbar from "../Navbar/Topbar";
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    position: 'relative',
    overflowY: 'auto',
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('lg')]: {
        marginLeft: `${theme.sidebar.width}`
      },
    }),
  }),
);

const SidebarLayout = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  return (
    <>
      <Box sx={{ flex: 1, height: '100%', overflowY:'auto'}}>
        <Topbar />
        <Sidebar />

        <Box sx={{ position: 'relative', zIndex: 6, display: 'block', flex: 1, }}>
          <Main display="block"  open={sidebarToggle}>
              <Outlet />
          </Main>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
